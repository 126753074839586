.wrap {
  height: 1em;
  border-radius: 0.5em;
}

.bar {
  height: 100%;
  border-radius: 0.5em;
  transition: all ease 0.3s;
}
