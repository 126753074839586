.label {
  display: block;
  font-family: var(--font-family-primary), sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
  width: 100%;
  transition: all 0.3s;

  &.error {
    color: var(--error-main);
  }
}
