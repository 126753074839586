@import '../../../styles/breakpoints.sass';

.wrap {
  display: flex;
  min-height: 100vh;

  main {
    width: 100%;
  }

  .container {
    padding-bottom: 30px;
    position: relative;

    @include breakpoint('xl', 'up') {
      padding: 0 42px 60px;
    }
  }
}

.btnWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include breakpoint('md', 'down') {
    justify-content: center;
  }

  h1 {
    margin-right: 22px;

    @include breakpoint('md', 'down') {
      margin: 10px;
    }
  }
}

.mobTitle {
  @include breakpoint('md', 'up') {
    display: none;
  }

  h1 {
    font-size: 24px;
    text-align: center;
  }
}

.mainContent {
  flex: 100;
}
