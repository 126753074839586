.select {
  font-family: var(--font-family-primary), var(--font-family-secondary),
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  transition: all 0.3s;
  width: 100%;
  max-width: 100%;

  :global {
    .reactSelect {
      &__control {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid var(--divider);
        min-height: 44px;
        background: transparent;

        &:hover {
          border-color: var(--primary-main);
        }

        &--is-focused {
          box-shadow: none;
        }

        &--is-disabled {
          opacity: 0.75;
        }
      }

      &__indicator-separator {
        display: none;
      }

      &__dropdown-indicator {
        color: var(--text-primary);

        &:hover {
          color: var(--primary-main);
        }

        svg {
          width: 1rem;
        }
      }

      &__value-container {
        padding-left: 0;
      }

      &__multi-value {
        background: var(--secondary-main);
        color: var(--secondary-contrastText);
        border-radius: 10px;
        line-height: 14px;
        padding: 0 15px;
        margin: 5px 10px 5px 0;
        font-size: 12px;
        font-weight: 600;

        &__label {
          color: inherit;
        }

        &__remove {
          cursor: pointer;
          padding: 0;

          &:hover {
            background-color: transparent;
            color: var(--error-main);
          }
        }
      }
    }
  }

  &.transparent {
    :global {
      .reactSelect {
        &__control {
          border-color: transparent;
        }
      }
    }
  }
}
