.btnWrap {
  margin-bottom: 10px;
}

.picture {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.photo {
  text-align: center;
  width: 80vw;
  height: 80vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  :global {
    .react-html5-camera-photo {
      width: 100%;
      height: 100%;
    }

    #inner-circle,
    #outer-circle {
      box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    }

    .react-html5-camera-photo > .display-error {
      width: 100%;

      ~ * {
        display: none;
      }
    }
  }
}
