.wrap {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.badge {
  position: absolute;
  padding: 0 6px;
  background: var(--warning-main);
  border-radius: 50%;
  color: var(--warning-contrastText);
  font-size: 14px;
  line-height: 20px;
  min-width: 20px;
  text-align: center;

  &.small {
    line-height: 16px;
    min-width: 16px;
    font-size: 10px;
    padding: 0 3px;
  }

  &.top {
    top: -6px;
  }

  &.bottom {
    bottom: -6px;
  }

  &.left {
    left: -6px;
  }

  &.right {
    right: -6px;
  }

  &.dot {
    width: 8px;
    height: 8px;
    padding: 0;
    min-width: 8px;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}
