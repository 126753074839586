.tabs {
  margin-bottom: 25px;
}

.or {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: var(--gray-900);
  margin: 20px 0 15px;
}

.socialWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 0;

  .item {
    background: currentcolor;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin: 0 15px;
    transition: all 0.3s;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 50%;
      box-shadow: 0 5px 10px currentcolor;
      opacity: 0.5;
    }

    &:hover {
      opacity: 0.85;
    }
  }
}
