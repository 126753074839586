.wrap {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.el {
  margin-right: 10px;
  color: var(--text-secondary);
}
