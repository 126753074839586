@import '../../../styles/breakpoints.sass';

.wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include breakpoint('md', 'up') {
    padding-top: 100px;
    background: var(--background-secondary);
  }
}

.copyright {
  margin-top: auto;
  text-align: center;
  color: var(--text-secondary);
  font-size: 14px;
  padding: 20px 0;
}
