@import '../../../styles/breakpoints.sass';

.row {
  display: flex;
  margin: 0 -15px;
}

.col {
  padding: 0 15px;
  flex: 0 0 auto;
  width: 100%;
}
