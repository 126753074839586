.wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  &.absolute,
  &.fixed {
    inset: 0;
  }

  &.absolute {
    position: absolute;
    z-index: 1;
    background: rgb(255 255 255 / 50%);
  }

  &.fixed {
    position: fixed;
    z-index: 999;
    background: rgb(0 0 0 / 50%);
  }

  &.hidden {
    display: none;
  }
}
