.slide {
  transform: translateX(100%);
}

// classNames={{
//   appear: 'my-appear',
//   appearActive: 'my-active-appear',
//   appearDone: 'my-done-appear',
//   enter: 'my-enter',
//   enterActive: 'my-active-enter',
//   enterDone: 'my-done-enter',
//   exit: 'my-exit',
//   exitActive: 'my-active-exit',
//   exitDone: 'my-done-exit',
//  }}

.enterActive {
  &.fade {
    opacity: 1;
    visibility: visible;
  }

  &.zoom {
    transform: scale(1);
  }

  &.slide {
    transform: translateX(0);
  }
}

.exitActive {
  &.fade {
    opacity: 0;
    visibility: hidden;
  }

  &.zoom {
    transform: scale(0);
  }

  &.slide {
    transform: translateX(-100%);
  }
}

.enter,
.enterDone {
  &.fade {
    opacity: 1;
    visibility: visible;
  }

  &.zoom {
    transform: scale(1);
  }

  &.slide {
    transform: translateX(0);
  }
}

.exit,
.exitDone {
  &.fade {
    opacity: 0;
    visibility: hidden;
  }

  &.zoom {
    transform: scale(0);
  }

  &.slide {
    transform: translateX(100%);
  }
}
