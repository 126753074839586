.item {
  display: flex;

  &:not(:first-child) {
    margin-bottom: 30px;
  }

  &.my {
    margin-left: auto;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 10px;

  .my & {
    display: none;
  }
}

.msgInner {
  padding: 10px 20px;
  border-radius: 20px 20px 20px 0;
  background-color: rgb(0 176 242 / 10%);
  max-width: 820px;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  white-space: pre-wrap;

  .my & {
    border-radius: 20px 20px 0;
    background-color: var(--gray-200);
  }
}

.mediaList {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  + * {
    margin-top: 10px;
  }

  .mediaItem {
    padding: 5px;
  }
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-300);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.file {
  .name {
    font-weight: 600;
  }

  .size {
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-secondary);
  }

  button {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
}

.time {
  font-size: 12px;
  line-height: 20px;
  color: var(--text-secondary);

  .my & {
    text-align: right;
  }
}
