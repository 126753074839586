@import '../../../styles/breakpoints.sass';

.wrap {
  display: flex;
  height: 100%;
  max-height: calc(100vh - 160px);

  @include breakpoint('xl', 'down') {
    max-height: calc(100vh - 130px);
  }

  @include breakpoint('lg', 'down') {
    overflow: hidden;
    position: relative;
    max-height: 100%;
  }
}

.list {
  width: 360px;

  @include breakpoint('lg', 'down') {
    width: 100%;
    min-height: 300px;
  }

  @include breakpoint('md', 'down') {
    max-height: calc(100vh - 193px);
  }
}

.body {
  padding-left: 30px;
  width: calc(100% - 360px);

  @include breakpoint('xl', 'down') {
    padding-left: 18px;
  }

  @include breakpoint('lg', 'down') {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0;
    left: -100%;
  }

  &.active {
    left: 0;
  }
}
