@import '../../../styles/breakpoints.sass';

.menu {
  padding: 10px;
  margin-left: 10px;
  color: var(--secondary-main);
  font-size: 24px;

  @include breakpoint('md', 'up') {
    display: none;
  }
}

.nav__link {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  font-family: var(--font-family-secondary), var(--font-family-primary),
    sans-serif;
  margin-right: 30px;
  color: var(--secondary-main);
}

.nav__link:hover {
  color: var(--primary-main);
}

.nav__box_wrap {
  @include breakpoint('md', 'down') {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 5;
  }
}

.nav__box {
  @include breakpoint('md', 'down') {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: var(--background-primary);
    box-shadow: 0 20px 50px rgb(5 31 95 / 5%);
  }

  img {
    display: none;

    @include breakpoint('md', 'down') {
      display: block;
    }
  }

  .nav__link {
    @include breakpoint('md', 'down') {
      padding: 10px;
      margin: 0;
    }
  }
}
