.footer {
  z-index: 4;
  background-color: var(--secondary-main);

  .footer__wrap {
    padding-top: 50px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .link__box {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 40px;
      border-bottom: 1px solid rgb(255 255 255 / 20%);

      .social {
        padding-top: 24px;
        font-size: 30px;
      }
    }

    .foot {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;

      p,
      a {
        font-family: var(--font-family-primary), sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: var(--divider);
      }

      a:hover {
        color: var(--background-primary);
        text-decoration: underline;
      }

      div {
        a:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
  }
}
