@import '../../../styles/breakpoints.sass';

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(var(--primary-main-rgb) / 10%);
  padding: 2px;
  border-radius: 13px;
  min-height: 26px;
}

.val {
  font-size: 12px;
  font-weight: 600;
  font-family: var(--font-family-secondary), sans-serif;
  color: var(--secondary-main);
  line-height: 1;
  padding: 0 8px;
}

.btnWrap {
  display: flex;

  * {
    margin-left: 5px;
  }
}

.ratingWrap {
  padding: 60px 50px;

  @include breakpoint('md', 'down') {
    padding: 50px 20px 60px;
  }

  .title {
    text-align: center;
    margin-bottom: 6px;
  }

  .description {
    text-align: center;
    font-size: 14px;
    max-width: 470px;
    margin: 0 auto 30px;
  }
}
