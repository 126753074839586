@import '../../../styles/breakpoints.sass';

.partner {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  padding: 20px 0;

  @include breakpoint('md', 'down') {
    display: block;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-300);
  }
}

.main__info__wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33%;
  min-width: 280px;

  @include breakpoint('lg', 'down') {
    width: 100%;
  }
}

.avatar {
  margin-right: 30px;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 50%;

  @include breakpoint('xl', 'down') {
    margin-right: 20px;
  }
}

.main__info {
  width: 100%;
  padding-right: 10px;

  .name {
    font-family: var(--font-family-secondary), sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: var(--secondary-main);
    margin-bottom: 5px;
  }

  .profession {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-900);
    word-break: break-all;
  }
}

.rating {
  margin-top: 6px;
  max-width: 220px;
  width: 100%;
}

.description {
  padding-right: 10px;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-900);
  flex: 1;
  min-width: 150px;

  @include breakpoint('lg', 'down') {
    width: 100%;
    padding-right: 0;
  }
}

.details {
  margin-bottom: 5px;

  @include breakpoint('md', 'down') {
    margin-top: 15px;
  }
}

.btnWrap {
  flex-shrink: 0;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint('md', 'up') {
    width: 224px;
  }

  @include breakpoint('md', 'down') {
    margin: 0 -3px;
    flex-wrap: wrap;
  }

  .item {
    @include breakpoint('md', 'up') {
      width: 100%;
    }

    @include breakpoint('md', 'down') {
      padding: 3px;
    }
  }
}
