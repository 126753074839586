.card {
  box-shadow: 0 5px 8px rgb(5 31 95 / 25%);
}

.wrap {
  padding: 10px 16px;
  min-width: 220px;
}

.link {
  display: block;
  color: var(--text-primary);
  transition: all ease 0.25s;
  font-weight: 600;
  font-size: 14px;

  &:hover {
    color: var(--primary-main);
  }
}
