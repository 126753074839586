.textField {
  font-family: var(--font-family-primary), sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  height: 44px;
  border-bottom: 1px solid var(--divider);
  transition: all 0.3s;
  width: 100%;
  max-width: 100%;

  &:disabled {
    color: rgb(var(--text-secondary-rgb) / 30%);
    border-color: rgb(var(--divider-rgb) / 30%);

    &::placeholder {
      color: rgb(var(--text-secondary-rgb) / 30%);
    }
  }

  &.textarea {
    min-height: 60px;
    height: auto;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: var(--primary-main);
    }
  }

  &.error {
    border-color: var(--error-main);
  }

  &.startIcon {
    padding-left: 40px;
  }

  &.endIcon {
    padding-right: 40px;
  }
}
