.wrap {
  position: relative;
  padding-bottom: 2px;

  .btn {
    display: flex;
    margin: 25px auto 0;
    max-width: 220px;
  }
}

.ratingTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  h5 {
    font-size: 15px;
    color: var(--text-primary);
  }
}

.row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;

  .inputWrap {
    width: 80px;
    flex-shrink: 0;
    text-align: center;

    input {
      text-align: center;
    }
  }

  .label {
    font-size: 14px;
    width: 100%;
    color: var(--text-secondary);

    &.disabled {
      opacity: 0.3;
    }
  }
}
