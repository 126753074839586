@import '../../../styles/breakpoints.sass';

.cardBody {
  position: relative;

  @include breakpoint('md', 'up') {
    padding: 40px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.avatar {
  padding: 0 15px;
  width: 100%;
  margin-bottom: 30px;

  @include breakpoint('lg', 'up') {
    width: 230px;
  }
}

.info {
  padding: 0 15px;
  width: 100%;

  @include breakpoint('lg', 'up') {
    width: calc(100% - 230px);
  }
}
