@import '../../../styles/breakpoints.sass';

.sideMenu {
  background: var(--background-primary);
  width: 270px;
  height: 100vh;
  padding: 45px 45px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  align-self: flex-start;
  position: sticky;
  top: 0;

  @include breakpoint('md', 'down') {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 21;
  }

  @media (max-height: 450px) {
    padding: 20px 45px;
  }

  &.hide {
    left: -270px;
  }

  .logo {
    align-self: center;
    padding-bottom: 52px;
    max-width: 120px;

    @media (max-height: 450px) {
      padding-bottom: 20px;
      max-width: 80px;
    }
  }

  .navigation {
    padding: 50px 0;
    flex: 100;
    width: 100%;
    overflow: hidden;

    @media (max-height: 450px) {
      padding: 20px 0;
    }
  }

  .menu__item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    align-self: flex-start;
    transition: all ease 0.25s;
    font-family: var(--font-family-secondary), var(--font-family-primary),
      sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--secondary-main);

    &:not(:last-child) {
      margin-bottom: 30px;

      @media (max-height: 450px) {
        margin-bottom: 20px;
      }
    }

    img {
      margin-right: 20px;

      @media (max-height: 450px) {
        height: 20px;
      }
    }

    &.active,
    &:hover {
      color: var(--primary-main);
    }
  }

  .logout {
    margin-top: auto;
  }
}
