@import '../../../styles/breakpoints.sass';

.wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 2;
  width: 300px;

  @include breakpoint('md', 'down') {
    width: calc(100% - 18px * 2);
    right: 18px;
  }
}
