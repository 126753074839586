.forgotWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: var(--primary-main);

    &:hover {
      text-decoration: underline;
    }
  }
}

.btnWrap {
  text-align: center;
  margin-top: 45px;

  button {
    min-width: 300px;
  }
}
