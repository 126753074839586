@import '../../../styles/breakpoints.sass';

.counter {
  display: flex;
  justify-content: space-between;
  height: 20px;
  border-radius: 10px;
  background-color: rgb(var(--primary-main-rgb) / 10%);

  &.fullWidth {
    width: 100%;
  }

  .input {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    text-align: center;

    &::-webkit-inner-spin-button {
      appearance: none;
      display: none;
    }
  }

  .btn {
    color: var(--secondary-main);
    font-size: 10px;
    padding: 5px;

    &:disabled {
      color: var(--text-secondary);
    }
  }
}
