.chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--font-family-secondary), sans-serif;
  font-size: 13px;
  line-height: 2;
  min-height: 2em;
  border: 2px solid;
  padding: 5px 40px;
  border-radius: 20px;
  transition: all 0.3s;
  font-weight: 600;
  -webkit-tap-highlight-color: transparent;

  &.fullWidth {
    width: 100%;
  }

  &.lg {
    font-size: 15px;
    padding: 7px 15px;
    border-radius: 24px;
  }

  &.sm {
    padding: 3px 15px;
    border-radius: 17px;
  }

  &.xs {
    border-width: 1px;
    font-size: 11px;
    border-radius: 11px;
    padding: 0 10px;
    line-height: 20px;
    min-height: 20px;
  }

  &.outlined {
    border: 2px solid currentcolor;

    &.primary {
      color: var(--primary-main);
    }

    &.secondary {
      color: var(--secondary-main);
    }

    &.success {
      color: var(--success-main);
    }

    &.warning {
      color: var(--warning-main);
    }

    &.error {
      color: var(--error-main);
    }

    &.default {
      color: var(--default-main);
    }
  }

  &.contained {
    &.primary {
      background: var(--primary-main);
      color: var(--primary-contrastText);
    }

    &.secondary {
      background: var(--secondary-main);
      color: var(--secondary-contrastText);
    }

    &.success {
      background: var(--success-main);
      color: var(--success-contrastText);
    }

    &.warning {
      background: var(--warning-main);
      color: var(--warning-contrastText);
    }

    &.error {
      background: var(--error-main);
      color: var(--error-contrastText);
    }

    &.default {
      background: var(--default-main);
      color: var(--default-contrastText);
    }
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  color: inherit;
  transition: all ease 0.25s;
  font-size: 8px;
  padding: 2px;

  &:hover {
    opacity: 0.7;
  }
}
