@import '../../../styles/breakpoints.sass';

.card {
  height: 100%;
}

.startScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include breakpoint('lg', 'down') {
    display: none;
  }

  .icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: var(--primary-main);
    color: var(--primary-contrastText);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 75px;
    margin-bottom: 15px;
  }
}

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 35px;

  @include breakpoint('md', 'down') {
    padding: 0;
  }
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  .back {
    font-size: 30px;
    color: var(--primary-main);
    margin-right: 10px;

    @include breakpoint('lg', 'up') {
      display: none;
    }

    svg {
      display: block;
    }
  }

  .avatar {
    width: 70px;

    @include breakpoint('md', 'down') {
      width: 40px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;

    .name {
      font-family: var(--font-family-secondary), var(--font-family-primary),
        sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: var(--secondary-main);
    }
  }
}

.messagesWrap {
  margin-top: auto;
  margin-right: -15px;
  overflow: hidden;
}

.message__field {
  flex: 100;
  padding: 20px 0;
  padding-right: 15px;
  display: flex;
  flex-direction: column-reverse;
}

.date {
  text-align: center;
  margin-bottom: 30px;
  color: var(--text-secondary);
  font-size: 12px;
}

.round {
  padding: 10px 0 20px;
}
