.experience__box,
.education__box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;

  .experience__head,
  .education__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h3 {
      font-family: var(--font-family-secondary), var(--font-family-primary),
        sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--gray-900);
    }

    .btn.add {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      min-width: 120px;
      padding: 1px;

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        font-family: var(--font-family-secondary), var(--font-family-primary),
          sans-serif;
        text-align: center;
        text-transform: uppercase;
        color: var(--primary-main);
        padding-left: 8px;
      }

      svg {
        fill: var(--primary-main);
      }
    }

    .btn.add:hover {
      text-decoration: none;
    }
  }

  .experience__list,
  .education__list {
    margin-bottom: 38px;
  }

  .experience__list {
    .experience__item {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 50px;

      .button__box {
        position: absolute;
        top: 2px;
        right: 0;
        display: flex;
        flex-direction: row;

        .btn5 {
          margin-bottom: 0;
          margin-left: 5px;
        }
      }

      .experience__info {
        max-width: 100%;
        flex-direction: column;
        align-items: flex-start;

        .name {
          width: calc(100% - 120px);
        }

        .details {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 11px;
          margin-top: 5px;

          .level {
            font-family: var(--font-family-primary), sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: var(--gray-900);
            margin-top: 0;
          }

          .years,
          .country {
            font-family: var(--font-family-primary), sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: var(--text-secondary);
          }
        }

        .description {
          font-family: var(--font-family-primary), sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: var(--gray-900);
        }
      }
    }
  }
}
