@import '../../../styles/breakpoints.sass';

.card {
  background: var(--background-primary);
  border-radius: 0.625rem;
  position: relative;

  &:not(.noPadding) {
    > * {
      &:first-of-type {
        padding-top: 50px;

        @include breakpoint('md', 'down') {
          padding-top: 20px;
        }
      }

      &:last-of-type {
        padding-bottom: 50px;

        @include breakpoint('md', 'down') {
          padding-bottom: 20px;
        }
      }
    }
  }
}

.header {
  padding: 50px 50px 10px;

  @include breakpoint('md', 'down') {
    padding: 20px 18px 10px;
  }
}

.body {
  padding: 10px 50px;

  @include breakpoint('md', 'down') {
    padding: 10px 18px;
  }
}

.footer {
  padding: 10px 50px 50px;

  @include breakpoint('md', 'down') {
    padding: 10px 18px 20px;
  }
}
