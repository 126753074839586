@import '../../../styles/breakpoints.sass';

.cardBody {
  @include breakpoint('md', 'up') {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
  }
}

.requests__list {
  margin-bottom: 30px;

  @include breakpoint('md', 'down') {
    padding-top: 18px;
    margin-bottom: 0;
  }

  .item {
    height: 100%;

    @include breakpoint('md', 'down') {
      height: auto;
      margin-bottom: 40px;
    }
  }

  .avatar {
    max-width: 200px;
    width: 100%;

    @include breakpoint('md', 'up') {
      margin: 0 auto 30px;
    }

    @include breakpoint('md', 'down') {
      max-width: 100px;
      margin-right: 20px;
    }
  }

  .main__info {
    @include breakpoint('md', 'up') {
      text-align: center;
    }

    @include breakpoint('md', 'down') {
      display: flex;
    }
  }

  h3 {
    color: var(--secondary-main);
    margin-bottom: 10px;

    @include breakpoint('md', 'down') {
      font-size: 20px;
      margin: 4px 0;
    }
  }

  .location {
    font-size: 16px;
    line-height: 25px;
    color: var(--text-secondary);

    &::before {
      content: url('../../../assets/img/icon/location.svg');
      padding-right: 5px;
    }
  }

  .rating {
    max-width: 220px;
    margin: 6px auto 0;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: var(--gray-900);
    margin-bottom: 10px;

    @include breakpoint('md', 'down') {
      font-size: 15px;
    }
  }

  .profile__info {
    margin-bottom: 20px;
  }

  .infoItem {
    color: var(--gray-900);
    font-size: 14px;
    line-height: 25px;

    @include breakpoint('md', 'down') {
      line-height: 27px;
      font-size: 16px;
      margin-bottom: 2px;
    }
  }

  .label {
    font-weight: 600;
    padding-right: 4px;
  }

  .message {
    margin-top: 20px;
  }

  .btn__group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    margin-top: auto;

    button {
      margin: 5px;
    }
  }
}

.pagination {
  @include breakpoint('md', 'up') {
    padding: 20px 30px;
  }

  .btn {
    max-width: 240px;
    display: flex;
    margin: 0 auto;
  }
}
