.w {
  display: inline-flex;
  flex-direction: column;

  &.fullWidth {
    width: 100%;
  }
}

.inputWrap {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 26px;
  color: var(--primary-main);
  display: flex;
  align-items: center;

  &.endIcon {
    right: 0;
  }

  &.startIcon {
    left: 0;
  }
}

.helperText {
  margin-top: 3px;
}
