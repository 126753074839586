@import '../../../styles/breakpoints.sass';

.cardBody {
  position: relative;
  height: 100%;

  @include breakpoint('md', 'up') {
    padding: 25px 40px;

    .small & {
      padding: 25px 30px;
    }
  }
}
