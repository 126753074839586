@import '../../../styles/breakpoints.sass';

.accordion {
  margin: 10px auto;
  overflow-anchor: none;
  background: var(--background-primary);
  border: 1px solid var(--gray-300);
  border-radius: 30px;

  @include breakpoint('md', 'down') {
    border-radius: 20px;
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }

    .content {
      height: auto;
      padding: 15px 40px 0;
    }
  }

  .title {
    transition: all 0.8s;
    position: relative;
    display: block;
    padding: 15px 40px;
    padding-right: 80px;
    font-family: var(--font-family-secondary), var(--font-family-primary),
      sans-serif;
    font-weight: 900;
    font-size: 20px;
    line-height: 1.5;
    cursor: pointer;
    width: 100%;
    color: var(--secondary-main);
    text-align: left;

    @include breakpoint('md', 'down') {
      font-size: 16px;
      padding: 10px 25px;
      padding-right: 60px;
    }

    &:hover {
      color: var(--primary-main);

      svg {
        fill: var(--primary-main);
      }
    }

    svg {
      position: absolute;
      right: 50px;
      top: 21px;
      transition: all 0.3s;
      fill: var(--secondary-main);

      @include breakpoint('md', 'down') {
        right: 20px;
      }
    }
  }

  .content {
    height: 0;
    overflow: hidden;
    display: block;

    p {
      margin-top: 0;
      margin-bottom: 1rem;
      color: var(--text-primary);
    }
  }
}
