.btnWrap {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  .col {
    width: 50%;
    padding: 0 15px;
  }
}
