.checkbox__label {
  display: flex;
  flex-flow: row wrap;
  cursor: pointer;
  position: relative;

  .customCheckbox {
    width: 16px;
    height: 16px;
    border: 1px solid var(--divider);
    border-radius: 2px;
    transition: all 0.3s;
    margin: 3px 0;

    &:hover {
      border-color: var(--primary-main);
    }

    &.error {
      border-color: var(--error-main);
    }

    &::after {
      content: '';
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background-image: url('../../../assets/img/icon/check.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      opacity: 0;
      transition: all 0.3s;
    }
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;

    &:checked {
      ~ .customCheckbox::after {
        opacity: 1;
        transition: all 0.3s;
      }
    }
  }

  .label {
    padding-left: 10px;
    line-height: 1.375;
  }
}
