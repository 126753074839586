.wrap {
  display: flex;
  align-items: center;
}

.notification {
  font-size: 30px;
  color: var(--secondary-main);
  transition: all ease 0.25s;

  &:hover {
    color: var(--primary-main);
  }
}

.profile__name {
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-family: var(--font-family-secondary), var(--font-family-primary),
    sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: var(--secondary-main);
  transition: all 0.3s;

  svg {
    margin-left: 8px;
  }
}

.profile__name:hover {
  cursor: pointer;
  color: var(--primary-main);
}

.profile__name.active {
  svg {
    transform: rotate(180deg);
  }
}

.user {
  display: flex;
}

.notificationsWrap {
  position: relative;
  margin-right: 30px;
  z-index: 2;

  .notificationsList {
    position: absolute;
    top: 100%;
    right: 0;
  }
}
