@import '../../../styles/breakpoints.sass';

.topic__info {
  width: 100%;

  .topic__head {
    width: 100%;
    margin-bottom: 5px;

    h3 {
      font-size: 15px;
      line-height: 22px;
    }
  }

  .topic__details {
    .description {
      display: flex;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: var(--gray-900);
    }

    .th {
      font-weight: 600;
      padding-right: 5px;
    }

    .details {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      width: 100%;

      div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        padding-right: 5px;

        @include breakpoint('lg', 'up') {
          width: 30%;
        }
      }
    }
  }
}
