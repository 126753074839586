@import '../../../styles/breakpoints.sass';

.message__form {
  width: 100%;
  border-top: 1px solid var(--gray-300);

  .file {
    font-size: 29px;
    transition: all 0.3s;
    color: var(--text-secondary);
    cursor: pointer;

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      overflow: hidden;
    }

    &:hover {
      color: var(--primary-main);
    }

    svg {
      display: block;
    }
  }

  .emoji {
    transition: all 0.3s;
    color: var(--text-secondary);
    font-size: 30px;
    display: flex;

    &:hover {
      color: var(--primary-main);
    }
  }

  .wrap {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}

.textFieldWrap {
  max-height: 96px;
  overflow: hidden;
  width: 100%;

  .content {
    min-height: 24px;
    padding: 0 30px;
    word-break: break-word;
    white-space: pre-wrap;
    position: relative;
    color: transparent;
    line-height: 24px;
    font-size: 16px;
  }

  textarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--gray-900);
    padding: 0 30px;
    line-height: 24px;
    font-size: 16px;

    @include breakpoint('md', 'down') {
      padding: 0 10px;
    }
  }
}

.attattachment__listWrap {
  margin: 0 -5px;
}

.attattachment__list {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0;

  .attachmentWrap {
    padding: 5px;
  }

  .fileIcon {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background: var(--gray-200);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .preview {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .close {
    position: absolute;
    top: 2px;
    right: 2px;
    transition: all 0.3s;
    color: var(--secondary-main);

    &:hover {
      color: var(--error-main);
    }
  }
}

.submit {
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-main);
  margin-left: 30px;
  color: var(--primary-contrastText);
  font-size: 24px;
  transition: all 0.3s;
  position: relative;

  &:not(:disabled) {
    &:hover {
      @include breakpoint('md', 'up') {
        background: var(--secondary-main);
      }
    }
  }

  &.uploading {
    background: var(--warning-main);
    cursor: default;
  }

  .upload {
    animation: pulse 1s linear infinite;
  }
}

@keyframes pulse {
  50% {
    transform: scale(1.1);
  }
}

.emojiWrap {
  position: relative;

  .picker {
    position: absolute;
    right: 0;
    bottom: 100%;
    z-index: 10;

    @include breakpoint('md', 'down') {
      right: -70px;
    }
  }
}
