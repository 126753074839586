.card {
  box-shadow: 0 20px 50px rgb(5 31 95 / 25%);
  cursor: default;
  margin-top: 10px;
  word-break: break-all;
}

.wrap {
  width: 100%;
  padding: 10px 30px 10px 15px;
}

.btn {
  position: absolute;
  right: 13px;
  top: 15px;
  padding: 2px;
  font-size: 10px;
  transition: all ease 0.25s;

  &:hover {
    opacity: 0.75;
  }
}

.fullNotification {
  display: flex;
  align-items: center;

  .avatar {
    width: 50px;
    margin-right: 10px;
  }

  h5 {
    font-size: 16px;
  }
}

.link {
  display: block;
  color: inherit;
}
