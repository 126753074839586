// the breakpoints must be the same as the ./breakpoints.ts file
@use "sass:map"

$grid-breakpoints: (xs: 0, sm: 526px, md: 768px, lg: 1024px, xl: 1280px, xxl: 1430px)

@mixin breakpoint($name, $direction)
  @if not map.has-key($grid-breakpoints, $name)
    @warn "Warning: `#{$name}` is not a valid breakpoint name."
  @else
    @if $direction == 'down'
      @media (max-width: map.get($grid-breakpoints, $name) - 1)
        @content
    @if $direction == 'up'
      @media (min-width: map.get($grid-breakpoints, $name))
        @content
     