@import '../../../styles/breakpoints';

.loader {
  flex: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner {
  background-image: url('../../../assets/img/banner-baking.svg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 75.55vw auto;
  padding: 210px 0 130px;
  margin-top: 0;

  @include breakpoint('md', 'down') {
    padding-top: 120px 0;
  }

  h1 {
    font-weight: 900;
    font-size: 48px;
    line-height: 1;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @include breakpoint('md', 'down') {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .banner__img {
    @include breakpoint('xl', 'down') {
      width: auto;
      max-width: 35vw;
    }

    @include breakpoint('md', 'down') {
      order: 0;
      max-width: 100%;
      margin-bottom: 1rem;
    }
  }

  .text__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 70px;
    max-width: 600px;

    @include breakpoint('md', 'down') {
      align-items: center;
      justify-content: flex-start;
      margin-right: 0;
      max-width: 80%;
      text-align: center;
      order: 1;
    }

    @include breakpoint('sm', 'down') {
      max-width: 100%;
    }

    .content {
      color: var(--secondary-main);
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      padding-top: 30px;
      padding-bottom: 50px;

      @include breakpoint('md', 'down') {
        font-size: 16px;
        padding-top: 20px;
        padding-bottom: 30px;
      }
    }
  }
}

.about,
.faq,
.callBack,
.why {
  h2 {
    font-weight: 900;
    font-size: 36px;
  }
}

.about,
.why {
  padding: 100px 0;
  overflow: hidden;

  @include breakpoint('lg', 'down') {
    padding: 50px 0;
  }

  @include breakpoint('md', 'down') {
    padding: 25px 0;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include breakpoint('md', 'down') {
      flex-direction: column;
    }
  }

  .image {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 50%;

    @include breakpoint('md', 'down') {
      order: 1;
      width: 80%;
    }

    img {
      max-width: 100%;
    }

    .screenWrap {
      position: relative;
      margin: 0 55px 55px 0;
    }

    .screen {
      position: relative;
      border: 10px solid var(--background-primary);
      border-radius: 10px;
      box-shadow: 0 20px 50px rgb(5 31 95 / 5%);

      &:last-child {
        position: absolute;
        right: -55px;
        bottom: -55px;
      }
    }

    .backing {
      position: absolute;
      left: -85px;
      top: -80px;

      @include breakpoint('md', 'down') {
        left: -45px;
      }

      @include breakpoint('md', 'down') {
        top: 25px;
      }
    }
  }

  .text__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    padding-left: 15px;

    @include breakpoint('md', 'down') {
      width: 100%;
    }

    h2 {
      padding-bottom: 20px;

      @include breakpoint('md', 'down') {
        text-align: center;
        width: 100%;
      }
    }

    p {
      padding-bottom: 20px;
    }

    ul {
      padding-top: 5px;

      li {
        position: relative;
        padding-left: 44px;

        p {
          padding-bottom: 8px;
        }
      }

      li::before {
        position: absolute;
        left: 0;
        top: 0;
        content: url('../../../assets/img/special-icons/list-check.svg');
      }
    }
  }
}

.about {
  .image {
    align-items: flex-start;
    justify-content: flex-end;

    .screenWrap {
      position: relative;
      margin: 0 0 55px 55px;
    }

    .screen {
      box-shadow: none;
      border: none;
      border-radius: 0;

      &:last-child {
        position: absolute;
        right: auto;
        left: -55px;
      }
    }

    .backing {
      left: auto;
      right: -60px;

      @include breakpoint('md', 'down') {
        right: -45px;
        left: auto;
      }

      @include breakpoint('lg', 'down') {
        top: 20px;
        left: auto;
      }
    }
  }

  .text__box {
    padding-left: 0;
    padding-right: 15px;

    p {
      margin-bottom: 0;
    }

    span {
      font-weight: 600;
      font-size: 16px;
    }

    span.name {
      font-size: 18px;
      line-height: 40px;
      color: var(--secondary-main);
    }
  }
}

.social {
  padding-top: 100px;
  font-size: 60px;

  @include breakpoint('lg', 'down') {
    padding-top: 60px;
    font-size: 40px;
  }

  @include breakpoint('sm', 'down') {
    padding-top: 20px;
    font-size: 30px;
  }

  .links {
    display: flex;
    justify-content: center;
  }
}

.faq {
  padding-top: 100px;

  @include breakpoint('md', 'down') {
    padding-top: 50px;
  }

  h2 {
    margin-bottom: 40px;
    text-align: center;
  }

  .faq__content {
    width: 100%;
  }
}

.callBack {
  margin-top: 100px;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 90px;
  background: url('../../../assets/img/callBack-backing.png') no-repeat top/100%
    100%;

  @include breakpoint('lg', 'down') {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  h2 {
    margin-bottom: 50px;
    text-align: center;
  }
}
