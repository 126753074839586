@import '../../../styles/breakpoints.sass';

.w {
  h1 {
    text-align: center;
    margin-bottom: 40px;

    @include breakpoint('md', 'down') {
      margin-bottom: 30px;
    }
  }

  h2 {
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 30px;
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  p {
    display: inline-block;
    margin-bottom: 10px;
    text-align: justify;
  }

  blockquote {
    padding: 20px;
    border: 2px solid var(--primary-main);
    border-radius: 20px;
    background: var(--active-element-background);
    font-size: 16px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;

    p {
      font-style: italic;
      font-weight: 600;
    }
  }

  ul,
  ol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    padding-left: 25px;

    li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 10px;

      p {
        display: block;
        margin-bottom: 0;
      }
    }
  }

  ul {
    li::before {
      position: absolute;
      left: -25px;
      top: 0;
      content: url('../../../assets/img/special-icons/list-check.svg');
    }
  }

  ol {
    list-style: decimal;

    li::marker {
      color: var(--primary-main);
      font-weight: 600;
    }
  }

  a {
    display: inline-block;
    word-wrap: break-word;
    color: var(--primary-main);
    transition: all 0.3s;
  }

  a:hover {
    color: var(--secondary-main);
  }

  img {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
  }
}
