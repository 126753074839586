@import '../../../styles/breakpoints.sass';

.header {
  @include breakpoint('md', 'down') {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background: var(--background-primary);
    z-index: 2;
  }

  .header__wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    padding: 25px 0;

    @include breakpoint('md', 'down') {
      padding: 25px 0;
    }

    h1 {
      white-space: nowrap;

      @include breakpoint('md', 'down') {
        display: none;
      }
    }

    .logo {
      width: 50px;

      @include breakpoint('md', 'up') {
        display: none;
      }
    }

    .header__nav {
      padding-top: 0;
    }

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @include breakpoint('md', 'down') {
        display: none;
      }
    }
  }

  .menu {
    color: var(--secondary-main);
    font-size: 24px;
    margin-right: auto;
    margin-left: 20px;

    @include breakpoint('md', 'up') {
      display: none;
    }
  }
}
