@import '../../../styles/breakpoints.sass';

.criterions {
  margin: 0 auto 40px;
  max-width: 400px;
  width: 100%;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .name {
      font-size: 14px;
      font-weight: 600;
      color: var(--secondary-main);
      line-height: 1;
    }

    .counter {
      width: 200px;
      margin-left: auto;

      @include breakpoint('md', 'down') {
        width: 150px;
      }
    }

    .btnBox {
      margin-left: 10px;

      .btn {
        width: 20px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
      }
    }
  }
}

.inputWrap {
  width: 100%;
}

.btnWrap {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;
}
