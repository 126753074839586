.wrap {
  position: fixed;
  inset: 0;
  z-index: 999999;
  background: rgb(0 0 0 / 75%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  padding: 20px 15px;
  background: var(--background-primary);
  text-align: center;
  border-radius: 15px;

  button {
    margin-top: 10px;
  }
}
