@import '../../../styles/breakpoints.sass';

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background: transparent;

  @include breakpoint('md', 'down') {
    position: fixed;
    background: var(--background-primary);
    box-shadow: 0 20px 50px rgb(5 31 95 / 5%);
  }
}

.header__wrap {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint('md', 'down') {
    margin-top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    flex-shrink: 0;

    @include breakpoint('md', 'down') {
      width: 60px;
    }
  }

  .header__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 10px;

    @include breakpoint('md', 'down') {
      padding-top: 0;
      justify-content: space-between;
      position: relative;
    }

    .btn__group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-left: 20px;

      button {
        @include breakpoint('lg', 'down') {
          min-width: auto;
          padding: 5px 12px;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .avatar {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-left: 20px;

      .notification {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
      }

      .user {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
