@import '../../../styles/breakpoints.sass';

.wrap {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include breakpoint('md', 'down') {
    flex-wrap: wrap;
    justify-content: center;
  }

  .logo {
    margin-right: 50px;
    width: 80px;

    @include breakpoint('md', 'down') {
      margin: 0 auto 30px;
      width: 120px;
    }
  }

  .textWrap {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--text-secondary);

    @include breakpoint('md', 'down') {
      width: 100%;
      text-align: center;
    }

    h2 {
      font-weight: 600;
      font-size: 18px;
      color: var(--text-secondary);
    }

    h3 {
      font-size: 24px;
      color: var(--gray-900);
      font-weight: 600;
    }
  }
}
