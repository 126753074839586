@import './reset.css';
@import './fonts.css';
@import './breakpoints.sass';
@import './swiper';

html,
body {
  font-size: 16px;
  min-height: 100vh;
}

body {
  font-family: var(--font-family-primary), Arial, Helvetica, sans-serif;
  line-height: 1.56;
  color: var(--text-primary);

  @include breakpoint('md', 'up') {
    background-color: var(--background-secondary);
  }
}

a {
  text-decoration: none;
}

button,
input,
select,
textarea {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font: inherit;
}

input,
select,
textarea {
  color: var(--gray-900);
}

textarea {
  resize: none;
}

button {
  font-family: var(--font-family-secondary), var(--font-family-primary),
    sans-serif;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

img {
  display: block;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-secondary), var(--font-family-primary),
    sans-serif;
  color: var(--secondary-main);
  font-weight: 600;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

@media (max-width: 1023px) {
  body,
  html {
    font-size: 14px;
  }
}
