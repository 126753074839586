.wrap {
  display: flex;
  align-items: center;
  margin: 0 -0.5em;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5em;
  transition: all 0.3s;
  color: var(--primary-main);

  &:hover {
    color: var(--secondary-main);
  }

  &.white {
    color: var(--primary-contrastText);

    &:hover {
      color: var(--primary-main);
    }
  }
}
