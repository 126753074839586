.badges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 5px;
  width: 100%;

  > * {
    margin: 0 5px 5px 0;
  }
}
