@import '../../../styles/breakpoints.sass';

.wrap {
  padding: 50px 0;

  @include breakpoint('md', 'down') {
    padding: 40px 0;
  }

  :global {
    .swiper-wrapper {
      @include breakpoint('xl', 'down') {
        padding-bottom: 40px;
      }
    }

    .swiper-slide {
      height: auto;
    }
  }
}

.item {
  padding: 0 50px;
  font-size: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;

  h4 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include breakpoint('md', 'down') {
      margin-bottom: 20px;
    }
  }

  .imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-bottom: 30px;

    @include breakpoint('md', 'down') {
      margin-bottom: 20px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .descripton {
    font-weight: 600;
    text-align: center;
    line-height: 1.428;
    margin-bottom: 20px;
  }

  .list {
    padding: 0 20px;
    margin-bottom: auto;

    @include breakpoint('md', 'down') {
      padding: 0 10px;
    }

    li {
      display: flex;
    }

    svg {
      color: var(--primary-main);
      margin-right: 10px;
      margin-top: 3px;
      flex-shrink: 0;
      font-size: 18px;
    }
  }

  .subscribeTitle {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--secondary-main);
    padding-top: 34px;

    @include breakpoint('md', 'down') {
      padding-top: 20px;
    }
  }

  .price {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    padding-top: 20px;
    color: var(--secondary-main);
    font-family: var(--font-family-secondary), sans-serif;

    @include breakpoint('md', 'down') {
      padding-top: 15px;
    }

    sup {
      vertical-align: text-top;
      font-size: 0.6em;
    }
  }

  .priceDetails {
    text-align: center;
    margin: 10px 0 20px;
    min-height: 44px;
  }

  .perMonth {
    color: var(--text-secondary);
  }
}
