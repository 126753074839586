.modal {
  .imgWrap {
    min-width: 150px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 90vw;
    max-height: 90vh;
  }
}
