@import '../../../styles/breakpoints.sass';

.item {
  font-family: var(--font-family-primary), sans-serif;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  .row {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .name {
    font-weight: 600;
    line-height: 25px;
  }

  .level {
    font-size: 14px;
    line-height: 22px;
  }

  .secondaryText {
    font-size: 14px;
    line-height: 22px;
    color: var(--text-secondary);
  }

  .textRight {
    @include breakpoint('sm', 'up') {
      text-align: right;
    }
  }

  .button__box {
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;

    button:not(:last-child) {
      margin-right: 5px;
    }
  }
}
