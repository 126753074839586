.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.more {
  font-family: var(--font-family-secondary), sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  margin: 0 25px;
  color: var(--secondary-main);
}

.btn {
  min-width: 40px;
  padding: 5px;

  + .btn {
    margin-left: 10px;
  }
}
