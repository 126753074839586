@import '../../../styles/breakpoints';

.row {
  position: relative;

  @include breakpoint('xl', 'up') {
    display: flex;
  }
}

.searchForm {
  width: 360px;
  flex-shrink: 0;

  @include breakpoint('xl', 'down') {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    width: auto;
    display: none;
  }

  @include breakpoint('md', 'down') {
    top: 20px;
  }

  &.open {
    display: block;
  }

  .searchCard {
    padding: 30px 0;
    height: 100vh;

    @include breakpoint('xl', 'up') {
      position: sticky;
      top: 0;
      padding: 0;
    }

    @include breakpoint('lg', 'down') {
      height: 100%;
    }

    .formWrap {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .form {
      overflow: hidden;
    }
  }

  .cardBody {
    padding: 35px 30px;
  }

  h4 {
    color: var(--gray-900);
    margin-bottom: 20px;

    @include breakpoint('xl', 'down') {
      display: none;
    }
  }
}

.result {
  @include breakpoint('xl', 'up') {
    width: calc(100% - 360px);
    padding-right: 30px;
  }

  .nothingFound {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 0;
  }

  .hidden {
    @include breakpoint('xl', 'down') {
      opacity: 0;
      visibility: hidden;
    }
  }

  > div {
    height: 100%;
  }

  .searchInfo {
    display: flex;
    align-items: flex-start;

    @include breakpoint('xl', 'down') {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .resultList {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .count {
    font-weight: 600;
    margin-right: 30px;
    white-space: nowrap;

    @include breakpoint('xl', 'up') {
      padding-top: 5px;
    }
  }

  .displaySearchForm {
    font-size: 14px;
    font-family: inherit;

    @include breakpoint('xl', 'up') {
      display: none;
    }

    svg {
      font-size: 11px;
      margin-left: 5px;
      transition: all ease 0.25s;
    }

    &.open {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}

.pagination {
  border-top: 1px solid var(--gray-300);
  padding-top: 20px;
  margin-top: auto;
}
