.text__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 34px;

  h2 {
    font-family: var(--font-family-secondary), var(--font-family-primary),
      sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--gray-900);
    padding-bottom: 10px;
  }

  p {
    font-family: var(--font-family-primary), sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--gray-900);
  }

  h2,
  p {
    text-align: center;
  }
}
