@import '../../../styles/breakpoints.sass';

.header {
  font-family: var(--font-family-secondary), var(--font-family-primary),
    sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--text-secondary);

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  h2 {
    font-family: var(--font-family-secondary), var(--font-family-primary),
      sans-serif;
    font-size: 24px;
    color: var(--gray-900);
    font-weight: 600;
  }
}

.hidden {
  display: none;
}

.pictureWrap {
  width: 240px;
  margin: 0 auto;

  .btnWrap {
    margin-bottom: 10px;
  }
}

.picture {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.photo {
  text-align: center;
  padding: 0 20px;

  video {
    max-width: 100%;
    margin: 0 auto;
  }
}

.error {
  margin-bottom: 1rem;
}

.submit {
  text-align: center;
  margin-top: 2rem;
}

.navigation {
  display: flex;
  justify-content: space-between;
}

.modal {
  margin: -154px -50px -50px;
  background: var(--background-primary);
  border-radius: 10px;
  position: relative;

  @include breakpoint('md', 'down') {
    margin: -242px -18px 0;
  }
}
