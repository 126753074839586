@import '../../../styles/breakpoints.sass';

.profile {
  display: flex;
  flex-wrap: wrap;

  .settings {
    width: 100%;

    @include breakpoint('md', 'up') {
      margin-top: 30px;
    }

    @include breakpoint('xl', 'up') {
      margin-top: 0;
      width: 280px;
    }

    @include breakpoint('xxl', 'up') {
      width: 360px;
    }

    h4 {
      color: var(--gray-900);
      margin-bottom: 15px;
    }

    > div {
      height: 100%;
    }

    button {
      display: flex;
      width: 220px;
      margin: 0 auto;
    }
  }

  .profileInfo {
    width: 100%;

    @include breakpoint('xl', 'up') {
      padding-right: 30px;
      width: calc(100% - 280px);
    }

    @include breakpoint('xxl', 'up') {
      width: calc(100% - 360px);
    }
  }
}
