.wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background: var(--default-main);
  color: var(--default-contrastText);
  vertical-align: top;
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%;
  }

  svg,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
