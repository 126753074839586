@import '../../../styles/breakpoints.sass';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--font-family-secondary), sans-serif;
  font-size: 13px;
  line-height: 2;
  min-height: 2em;
  border: 2px solid;
  padding: 5px 40px;
  border-radius: 20px;
  min-width: 150px;
  transition: all 0.3s;
  font-weight: 600;
  -webkit-tap-highlight-color: transparent;

  @include breakpoint('md', 'down') {
    padding: 5px 20px;
  }

  &.primary {
    border-color: var(--primary-main);
    color: var(--primary-main);
  }

  &.secondary {
    border-color: var(--secondary-main);
    color: var(--secondary-main);
  }

  &.success {
    border-color: var(--success-main);
    color: var(--success-main);
  }

  &.warning {
    border-color: var(--warning-main);
    color: var(--warning-main);
  }

  &.error {
    border-color: var(--error-main);
    color: var(--error-main);
  }

  &.default {
    border-color: var(--default-main);
    color: var(--default-main);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:not(:disabled) {
    &:hover {
      &.primary {
        background: var(--primary-main);
        color: var(--primary-contrastText);
      }

      &.secondary {
        background: var(--secondary-main);
        color: var(--secondary-contrastText);
      }

      &.success {
        background: var(--success-main);
        color: var(--success-contrastText);
      }

      &.warning {
        background: var(--warning-main);
        color: var(--warning-contrastText);
      }

      &.error {
        background: var(--error-main);
        color: var(--error-contrastText);
      }

      &.default {
        background: var(--default-main);
        color: var(--default-contrastText);
      }
    }
  }

  &.contained {
    &.primary {
      background: var(--primary-main);
      color: var(--primary-contrastText);
    }

    &.secondary {
      background: var(--secondary-main);
      color: var(--secondary-contrastText);
    }

    &.success {
      background: var(--success-main);
      color: var(--success-contrastText);
    }

    &.warning {
      background: var(--warning-main);
      color: var(--warning-contrastText);
    }

    &.error {
      background: var(--error-main);
      color: var(--error-contrastText);
    }

    &.default {
      background: var(--default-main);
      color: var(--default-contrastText);
    }

    &:not(:disabled) {
      &:hover {
        &.primary {
          border-color: var(--primary-light);
          background: var(--primary-light);
        }

        &.secondary {
          border-color: var(--secondary-light);
          background: var(--secondary-light);
        }

        &.success {
          border-color: var(--success-light);
          background: var(--success-light);
        }

        &.warning {
          border-color: var(--warning-light);
          background: var(--warning-light);
        }

        &.error {
          border-color: var(--error-light);
          background: var(--error-light);
        }

        &.default {
          border-color: var(--default-light);
          background: var(--default-light);
        }
      }
    }
  }

  &.shadow {
    box-shadow: 0 5px 10px var(--btn-shadow);
  }

  &.lg {
    font-size: 15px;
    padding: 7px 15px;
    border-radius: 24px;
  }

  &.sm {
    padding: 0 8px;
    border-radius: 17px;
  }

  &.xs {
    border-width: 1px;
    font-size: 11px;
    border-radius: 11px;
    padding: 0 10px;
    min-width: auto;
    line-height: 20px;
    min-height: 20px;
  }

  &.fullWidth {
    width: 100%;
  }
}

.link {
  color: var(--primary-main);
  font-family: var(--font-family-primary), sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.startIcon {
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.endIcon {
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
