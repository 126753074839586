@import '../../../styles/breakpoints';

.tooltip {
  position: relative;

  &:hover {
    @include breakpoint('md', 'up') {
      .text {
        display: block;
      }
    }
  }

  .text {
    position: absolute;
    display: none;
    white-space: nowrap;
    background: var(--gray-200);
    border-radius: 5px;
    font-size: 0.75rem;
    padding: 10px 15px;
    top: -7px;
    right: -29px;
    z-index: 1;
    transform: translate(0, -100%);
    box-shadow: 5px 10px 10px rgb(0 0 0 / 10%);

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 9px 0 10px;
      border-color: var(--gray-200) transparent transparent transparent;
      top: 100%;
      right: 30px;
    }
  }
}
