@import '../../../styles/breakpoints.sass';

.cardBody {
  position: relative;

  @include breakpoint('md', 'up') {
    padding: 40px;
  }

  h2 {
    margin-bottom: 1em;
  }
}
