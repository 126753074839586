@import '../../../styles/breakpoints.sass';

.wrap {
  width: 100%;
  font-weight: 600;
  font-family: var(--font-family-secondary), var(--font-family-primary),
    sans-serif;
  text-align: left;
  color: var(--secondary-main);

  @include breakpoint('md', 'down') {
    font-size: 12px;
  }

  td {
    vertical-align: middle;

    &:last-child {
      text-align: right;
    }
  }
}

.progress {
  width: 100%;
  padding: 5px 20px;
}
