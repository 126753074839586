.tabs {
  width: auto;

  &.fullWidth {
    width: 100%;
  }
}

.wrap {
  display: flex;
  margin: 0 -2px;
}

.tab {
  width: 100%;
  font-family: var(--font-family-secondary), var(--font-family-primary),
    sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--gray-900);
  border-bottom: 4px solid var(--gray-900);
  text-align: center;
  white-space: nowrap;
  padding-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0 2px;

  &.active {
    color: var(--primary-main);
    border-bottom: 4px solid var(--primary-main);
  }
}

.switch:first-child {
  margin-right: 5px;
}

.switch:last-child {
  margin-left: 5px;
}

.switch:hover {
  color: var(--primary-main);
  border-bottom: 4px solid var(--primary-main);
}
