@import '../../../styles/breakpoints.sass';

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--primary-main);
  font-size: 10px;
  line-height: 1;
  font-weight: 600;
  border: 2px solid var(--primary-main);
  border-radius: 15px;
  min-height: 30px;
  min-width: 95px;
  padding: 2px 2px 2px 8px;

  &.center {
    justify-content: center;
    padding-left: 2px;
  }

  .value {
    margin-left: auto;
    padding: 0 10px;
    font-size: 16px;
  }

  &.horizontal {
    .btn {
      width: 60px;
    }
  }

  &.vertical {
    flex-direction: column;
    padding: 8px 15px;

    .value {
      font-size: 24px;
      margin-left: 0;
      padding: 10px;
    }
  }
}

.upgrade {
  text-align: center;

  a {
    color: var(--secondary-main);
    text-decoration: underline;
    font-size: 12px;
  }
}

.ratingModal {
  text-align: center;
  padding: 60px 80px;

  @include breakpoint('md', 'down') {
    padding: 50px 20px;
  }

  h3 {
    color: var(--text-primary);
    margin-bottom: 10px;

    @include breakpoint('md', 'down') {
      margin-bottom: 5px;
    }
  }

  .ko {
    margin-bottom: 30px;
  }

  .btn {
    margin: 40px auto 0;
  }
}
