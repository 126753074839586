@import '../../../styles/breakpoints.sass';

.topics__head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;

  .total__result {
    font-weight: 600;
    line-height: 30px;
    color: var(--gray-900);
    padding-right: 30px;
  }

  .tag__list {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      background: var(--primary-main);
      border-radius: 10px;
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;

      p {
        font-family: var(--font-family-primary), sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--btn-text-color);
        padding-right: 5px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          transition: all 0.3s;
          transform: rotate(45deg);
          fill: var(--btn-text-color);
        }
      }

      button:hover {
        svg {
          fill: var(--error-main);
        }
      }
    }
  }

  .sort {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    span {
      white-space: nowrap;
      padding-right: 5px;
    }

    span,
    select {
      font-family: var(--font-family-primary), sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.topic__list {
  .topic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    padding: 20px 0;
    position: relative;

    @include breakpoint('md', 'down') {
      flex-wrap: wrap;
    }

    &:not(:last-child) {
      border-bottom: 2px solid var(--gray-300);
    }

    .btnWrap {
      width: 180px;
      flex-shrink: 0;

      @include breakpoint('md', 'down') {
        margin: 0 auto;
      }
    }
  }

  h3 {
    @include breakpoint('md', 'down') {
      padding-right: 100px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    @include breakpoint('md', 'down') {
      position: absolute;
      right: 0;
      top: 20px;
    }

    * + * {
      margin-left: 5px;
    }
  }
}

.pagination {
  border-top: 1px solid var(--gray-300);
  padding-top: 20px;
  margin-top: auto;
}
