@import '../../../styles/breakpoints.sass';

.mainInfo {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  column-gap: 40px;
  margin-bottom: 50px;

  @include breakpoint('lg', 'down') {
    grid-template-columns: 150px 1fr;
    column-gap: 20px;
  }

  @include breakpoint('md', 'down') {
    grid-template-columns: 100px 1fr;
  }

  .avatar {
    grid-area: 1 / 1 / 3 / 2;

    @include breakpoint('md', 'down') {
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  .infoHead {
    grid-area: 1 / 2 / 2 / 3;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include breakpoint('md', 'down') {
      padding: 7px 0;
    }

    .name {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    h3 {
      margin-right: 20px;

      @include breakpoint('md', 'down') {
        font-size: 20px;
        margin-bottom: 5px;
      }
    }

    .experienceInfo {
      @include breakpoint('md', 'up') {
        display: flex;
        align-items: center;
        margin-top: 7px;
      }
    }

    .title {
      font-weight: 600;
      color: var(--gray-900);

      @include breakpoint('md', 'down') {
        font-size: 15px;
      }

      @include breakpoint('md', 'up') {
        padding-right: 30px;
      }
    }

    .location {
      color: var(--text-secondary);

      @include breakpoint('md', 'down') {
        font-size: 16px;
        margin-top: 7px;
      }

      &::before {
        content: url('../../../assets/img/icon/location.svg');
        padding-right: 5px;
      }
    }
  }

  .info {
    grid-area: 2 / 2 / 3 / 3;
    padding-top: 16px;
    font-size: 14px;

    @include breakpoint('md', 'down') {
      grid-area: 2 / 1 / 3 / 3;
      padding: 14px 0;
    }

    .row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 3px;
      }

      .th {
        color: var(--gray-900);
        font-weight: 600;
        width: 130px;
        padding-right: 10px;
        flex-shrink: 0;
      }
    }
  }
}

.info__wrap {
  margin-bottom: 33px;
  font-size: 14px;
  line-height: 1.57;

  h4 {
    color: var(--gray-900);
  }

  .info__head {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.edit {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-main);
  font-size: 24px;
  transition: all ease 0.25s;

  &:hover {
    color: var(--primary-main);
  }
}
