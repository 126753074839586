.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  flex: 100;

  h1 {
    font-size: 40px;
  }
}

.code {
  font-size: 150px;
  font-weight: 900;
  color: var(--secondary-main);
}
