.wrap {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.popup {
  background: var(--background-primary);
  border-radius: 0.5rem;
  position: relative;
  margin: 18px;

  &.fullWidth {
    width: 100%;
  }

  &.sm {
    max-width: 490px;
  }

  &.md {
    max-width: 750px;
  }

  &.xl {
    max-width: 1600px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    transition: all 0.3s;
    color: var(--text-primary);
    opacity: 0.7;
    font-size: 30px;

    &:hover {
      opacity: 1;
    }
  }
}

.content {
  max-width: calc(100vw - 36px);
  max-height: calc(100vh - 36px);
  overflow: hidden;
}

.shadow {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 50%);
  cursor: pointer;

  &.open {
    z-index: 5;
    opacity: 1;
    visibility: visible;
  }
}
