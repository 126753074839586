@import '../../../styles/breakpoints';

.text__page {
  margin-top: 160px;
  margin-bottom: 60px;

  @include breakpoint('md', 'down') {
    margin-bottom: 120px;
  }
}
