@import '../../../styles/breakpoints.sass';

.wrap {
  .row {
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;
  }

  .col {
    width: 50%;
    padding: 0 15px;

    @include breakpoint('md', 'down') {
      width: 100%;
    }
  }

  .btnWrap {
    text-align: center;
    margin-top: 30px;
  }

  h3 {
    text-align: center;
  }

  :global {
    .grecaptcha-badge {
      opacity: 0;
      visibility: hidden;
    }
  }
}
