@import '../../../styles/breakpoints.sass';

.contacts {
  height: 100%;
  display: flex;
}

.cardBody {
  padding: 40px 0 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @include breakpoint('md', 'down') {
    padding: 0;
  }
}

.contacts__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 30px 10px;
  flex-shrink: 0;

  @include breakpoint('md', 'down') {
    padding: 0 0 10px;
  }
}

.contacts__list {
  flex: 100;
  overflow: hidden;
}

.contactWrap {
  width: 100%;
  border-bottom: 1px solid var(--gray-300);
  transition: all 0.3s;
  position: relative;
  padding: 20px 30px;

  @include breakpoint('md', 'down') {
    padding: 20px 0;
  }

  &:hover,
  &.active {
    background: rgb(var(--primary-main-rgb) / 5%);
  }

  .contact {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
    }
  }

  .avatar {
    position: relative;
    width: 70px;
    height: 70px;
    flex-shrink: 0;
    margin-right: 20px;
  }

  .info {
    position: relative;
    width: calc(100% - 90px);

    .top {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 5px;

      .name {
        font-family: var(--font-family-secondary), var(--font-family-primary),
          sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: var(--secondary-main);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 10px;
      }

      .time {
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: var(--text-secondary);
      }
    }

    .bottom {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: space-between;

      p {
        font-size: 14px;
        line-height: 20px;
        color: var(--gray-900);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 20px;
        max-width: 250px;
      }

      .counter {
        margin-bottom: 2px;
        padding-right: 6px;
      }
    }
  }

  .round {
    position: relative;
    z-index: 1;
    margin-top: 10px;
  }
}
